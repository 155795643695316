<template>
  <AppLayout>
    <div class="wrapper row items-center q-col-gutter-md">
      <div v-for="block in blocks" :key="block.id" class="col-auto">
        <div class="block">
          <div class="header">
            <div class="icon-wrapper" :style="{ backgroundColor: block.color }">
              <BaseIcon :name="block.icon" is-white />
            </div>
            <div class="col q-ml-md">
              <div class="label" :style="{ color: block.color }">Initiator</div>
              <div class="type">{{ block.label }}</div>
            </div>
            <BaseActionButton is-flat no-border icon="eva-plus" />
          </div>

          <BaseSeparator has-inset class="q-my-md" />

          <div class="row q-col-gutter-sm q-px-md">
            <div>
              <div class="action">Approve</div>
            </div>
            <div>
              <div class="action">Reject</div>
            </div>
          </div>

          <BaseSeparator has-inset class="q-mt-md" />

          <div class="footer">
            <!-- <BaseIcon name="eva-alert-circle" color="red" /> -->
            <BaseIcon name="mdi-progress-check" class="q-mr-md" />
            <BaseIcon name="eva-bell-outline" />

            <q-space />

            <BaseActionButton is-flat no-border icon="eva-settings-outline" />
            <BaseActionButton is-flat no-border icon="eva-copy-outline" />
            <BaseActionButton
              is-flat
              no-border
              color="red"
              icon="eva-trash-2-outline"
            />
          </div>
        </div>
      </div>
    </div>
  </AppLayout>
</template>

<script>
import AppLayout from "@/layouts/app/AppLayout.vue";

export default {
  name: "Scratchpad",

  components: {
    AppLayout,
  },

  data() {
    return {
      content: "",
      // blocks: [
      //   {
      //     id: this.$nano.id(),
      //     label: "start",
      //     type: "START",
      //     icon: "mdi-flag",
      //     color: "#2bccba",
      //     group: "BASIC",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "end",
      //     type: "END",
      //     icon: "mdi-flag",
      //     color: "#fc5c65",
      //     group: "BASIC",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "internal user",
      //     type: "INTERNAL_USER",
      //     icon: "mdi-account",
      //     color: "#45aaf2",
      //     group: "BASIC",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "user group",
      //     type: "USER_GROUP",
      //     icon: "mdi-account-multiple",
      //     color: "#a65eea",
      //     group: "BASIC",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "dynamic user",
      //     type: "DYNAMIC_USER",
      //     icon: "mdi-account",
      //     color: "#f368E0",
      //     group: "ADVANCED",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "external user",
      //     type: "EXTERNAL_USER",
      //     icon: "mdi-account",
      //     color: "#4b7bec",
      //     group: "ADVANCED",
      //   },
      //   {
      //     id: this.$nano.id(),
      //     label: "condition",
      //     type: "CONDITION",
      //     icon: "mdi-share-variant",
      //     color: "#fd9644",
      //     group: "ADVANCED",
      //   },
      // ],
      blocks: [
        {
          id: this.$nano.id(),
          label: "dynamic user",
          type: "DYNAMIC_USER",
          icon: "mdi-account",
          color: "#f368E0",
          group: "ADVANCED",
        },
      ],
    };
  },

  computed: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.wrapper {
  .block {
    width: 320px;
    border-radius: 4px;
    background-color: var(--component-bg-color);
    display: flex !important;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      padding: 16px 16px 0px;

      .icon-wrapper {
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
      }

      .label {
        @extend .text-md;
        font-weight: 500;
        text-transform: capitalize;
      }

      .type {
        @extend .text-sm;
        color: var(--icon-color-inverted);
        text-transform: capitalize;
      }
    }

    .action {
      padding: 4px 8px;
      background-color: var(--body-bg-color);
      border-radius: 4px;
      font-weight: 500;
      color: var(--body-text-color);
    }

    .footer {
      padding: 8px 16px;
      display: flex;
      align-items: center;
    }
  }
}
</style>
